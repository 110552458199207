<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template v-slot:activator="{ on }">
			<v-btn icon color="warning" v-on="on">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span class="headline">{{ $t('offers.aboutTheOffer') }}</span>
				<v-spacer />
				<v-btn icon @click="switchEditMode()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-text-field
						v-model="title"
						:label="$t('newsfeed.title')"
						:rules="rules.title"
						prepend-inner-icon="mdi-format-title"
						outlined
						rounded
						hide-details="auto"
						class="pb-3"
					/>
					<v-row>
						<v-col cols="12" md="6" sm="12">
							<v-text-field
								v-model="location"
								:label="$t('events.place')"
								:rules="rules.location"
								prepend-inner-icon="mdi-map-marker-outline"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col cols="12" md="6" sm="12">
							<v-text-field
								v-model="website"
								:label="$t('companies.website')"
								:rules="rules.website"
								prepend-inner-icon="mdi-earth"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
					</v-row>
					<v-text-field
						v-model="capacity"
						:label="$t('events.capacity')"
						:rules="rules.capacity"
						placeholder="20"
						type="number"
						prepend-inner-icon="mdi-counter"
						outlined
						rounded
						hide-details="auto"
						class="pb-3"
					/>
					<v-row>
						<v-col cols="12" sm="6">
							<v-menu
								v-model="menu.start"
								eager
								offset-overflow
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
								min-width="auto"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							>
								<template #activator="{ on, attrs }">
									<v-text-field
										:value="startDateTimeText"
										:label="$t('offers.start')"
										:rules="rules.timestamp.start"
										prepend-inner-icon="mdi-calendar"
										outlined
										rounded
										hide-details="auto"
										class="pb-3"
										readonly
										v-bind="attrs"
										v-on="on"
									/>
								</template>
								<v-tabs v-model="startTab" grow>
									<v-tab key="startDateTab">
										<v-icon>mdi-calendar</v-icon>
									</v-tab>
									<v-tab key="startTimeTab">
										<v-icon>mdi-clock-outline</v-icon>
									</v-tab>
								</v-tabs>

								<v-tabs-items v-model="startTab">
									<v-tab-item key="startDateTab" eager>
										<v-date-picker v-model="timestamp.start.date" no-title scrollable @input="startTab = 1" />
									</v-tab-item>
									<v-tab-item key="startTimeTab" eager>
										<v-time-picker
											v-model="timestamp.start.time"
											format="24hr"
											@change="
												roundStartTime()
												menu.start = false
												startTab = 0
											"
										/>
									</v-tab-item>
								</v-tabs-items>
							</v-menu>
						</v-col>
						<v-col cols="12" sm="6">
							<v-menu
								v-model="menu.end"
								eager
								offset-overflow
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
								min-width="auto"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							>
								<template #activator="{ on, attrs }">
									<v-text-field
										:value="endDateTimeText"
										:label="$t('cv.end')"
										:rules="rules.timestamp.end"
										prepend-inner-icon="mdi-calendar"
										outlined
										rounded
										hide-details="auto"
										class="pb-3"
										readonly
										v-bind="attrs"
										v-on="on"
									/>
								</template>
								<v-tabs v-model="endTab" grow>
									<v-tab key="endDateTab">
										<v-icon>mdi-calendar</v-icon>
									</v-tab>
									<v-tab key="endTimeTab">
										<v-icon>mdi-clock-outline</v-icon>
									</v-tab>
								</v-tabs>

								<v-tabs-items v-model="endTab">
									<v-tab-item key="endDateTab" eager>
										<v-date-picker v-model="timestamp.end.date" no-title scrollable @input="endTab = 1" />
									</v-tab-item>
									<v-tab-item key="endTimeTab" eager>
										<v-time-picker
											v-model="timestamp.end.time"
											format="24hr"
											@change="
												roundEndTime()
												menu.end = false
												endTab = 0
											"
										/>
									</v-tab-item>
								</v-tabs-items>
							</v-menu>
						</v-col>
					</v-row>
					<v-textarea
						v-model="description"
						:label="$t('courses.description')"
						:counter="descriptionMaxLength"
						:rules="rules.description"
						outlined
						rounded
						hide-details="auto"
						class="pb-3"
						auto-grow
					/>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" text rounded plain @click="callEdit()">
					{{ $t('settings.edit') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

function roundTime(time, minutesToRound) {
	let [hours, minutes] = time.split(':')
	hours = parseInt(hours)
	minutes = parseInt(minutes)

	// Convert hours and minutes to time in minutes
	time = hours * 60 + minutes

	let rounded = Math.round(time / minutesToRound) * minutesToRound
	let rHr = '' + Math.floor(rounded / 60)
	let rMin = '' + (rounded % 60)

	return rHr.padStart(2, '0') + ':' + rMin.padStart(2, '0')
}

export default {
	name: 'EventInfoUpdateForm',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true,
			editMode: false,
			valid: false,
			title: '',
			location: '',
			website: '',
			capacity: null,
			description: '',
			menu: {
				start: false,
				end: false
			},
			startTab: 0,
			endTab: 0,
			timestamp: {
				start: {
					date: '',
					time: ''
				},
				end: {
					date: '',
					time: ''
				}
			},
			rules: {
				title: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.titleMaxLength) || this.$t('rules.length', { length: this.titleMaxLength })
				],
				location: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.locationMaxLength) || this.$t('rules.length', { length: this.locationMaxLength })
				],
				website: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.websiteMaxLength) || this.$t('rules.length', { length: this.websiteMaxLength })
				],
				capacity: [(v) => (!!v && v > 0) || this.$t('rules.required')],
				description: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.descriptionMaxLength) || this.$t('rules.length', { length: this.descriptionMaxLength })
				],
				timestamp: {
					start: [() => (!!this.timestamp.start.date && !!this.timestamp.start.time) || this.$t('rules.required')],
					end: [
						() => (!!this.timestamp.end.date && !!this.timestamp.end.time) || this.$t('rules.required'),
						() => this.endDateTimeText >= this.startDateTimeText || this.$t('rules.timestampEnd')
					]
				}
			}
		}
	},
	computed: {
		titleMaxLength() {
			return 255
		},
		locationMaxLength() {
			return 255
		},
		websiteMaxLength() {
			return 1024
		},
		descriptionMaxLength() {
			return 1024 * 4
		},
		nameMaxLength() {
			return 128
		},
		startDateTimeText() {
			return this.timestamp.start.date + ' ' + this.timestamp.start.time
		},
		endDateTimeText() {
			return this.timestamp.end.date + ' ' + this.timestamp.end.time
		},
		...mapGetters({
			event: 'events/event'
		})
	},
	created() {
		this.eventType = this.event.type
		this.title = this.event.title
		this.location = this.event.info.location
		this.website = this.event.info.website
		this.description = this.event.info.description
		this.capacity = this.event.attendances.maxCapacity
		this.timestamp.start = {
			date: this.$moment.utc(this.event.info.timestamp.start).format('YYYY-MM-DD'),
			time: this.$moment.utc(this.event.info.timestamp.start).format('HH:mm:ss')
		}
		this.timestamp.end = {
			date: this.$moment.utc(this.event.info.timestamp.end).format('YYYY-MM-DD'),
			time: this.$moment.utc(this.event.info.timestamp.end).format('HH:mm:ss')
		}
		this.loading = false
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		roundStartTime() {
			this.timestamp.start.time = roundTime(this.timestamp.start.time, 5)
		},
		roundEndTime() {
			this.timestamp.end.time = roundTime(this.timestamp.end.time, 5)
		},
		callEdit() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.editEvent({
				id: this.event.id,
				title: this.title,
				location: this.location,
				website: this.website,
				description: this.description,
				startDate: this.timestamp.start.date,
				endDate: this.timestamp.end.date,
				startTime: this.timestamp.start.time,
				endTime: this.timestamp.end.time,
				capacity: this.capacity
			}).then(() => {
				this.switchEditMode()
				this.loading = false
			})
		},
		...mapActions('events', ['editEvent'])
	}
}
</script>

<style scoped>
.v-menu__content {
	border-radius: 24px !important;
}
</style>
